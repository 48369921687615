body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.snackbar {
  border: 1px solid #e6e6e6;
}

.progress {
  padding: 6px 12px 6px 0;
  cursor: pointer;
  align-items: center;
}

.progress-rank {
  align-items: center;
  text-align: left;
}

.progress-bar {
  margin-left: 12px;
  position: relative;
  align-items: center;
}

.progress-line {
  position: relative;
  height: 1px;
  background: #dcdcdc;
  width: 100%;
  align-items: center;
}

.progress-marker {
  width: 30px;
  height: 30px;
  position: absolute;
  transition: left 200ms ease;
  transform: translate(-50%, 0);
}

.progress-marker .progress-value {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: black;
  font-size: 0.75em;
  font-weight: bold;
  background: yellow;
}

.progress-dots {
  width: 100%;
  justify-content: space-between;
}

.progress-dot {
  display: block;
  width: 0;
  position: relative;
}

.progress-dot::after {
  content: '';
  display: block;
  position: absolute;
  background-color: yellow;
  left: -5px;
  top: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: background-color 200ms 100ms ease;
}

.wordlist-word {
  text-align: left;
}

.wordlist-word::after {
  content: '';
  display: block;
  position: relative;
  width: 80%;
  height: 1px;
  background-color: white;
}

.controls-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.controls {
  position: relative;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 315px;
  padding-bottom: 45px;
}

.guess {
  text-transform: uppercase;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 2em;
  width: 100%;
  padding-top: 2px;
}

.guess-content {
  height: 100%;
  display: inline-block;
  min-width: 1px;
}

.guess-content::after {
  content: '';
  position: absolute;
  top: 0;
  width: 2px;
  height: 1.5em;
  background: yellow;
  -webkit-animation: blink 1000ms step-end 0s infinite normal none;
  animation: blink 1000ms step-end 0s infinite normal none;
}

@-webkit-keyframes blink {
  from,to {
      opacity: 1
  }

  50% {
      opacity: 0
  }
}

@keyframes blink {
  from,to {
      opacity: 1
  }

  50% {
      opacity: 0
  }
}

.hive-box {
  width: 100%;
  justify-content: center;
}

.hive {
  position: relative;
  width: 95%;
  padding-bottom: 100%;
  justify-content: center;
  margin: 25px auto;
}

.hive-cell {
  position: absolute;
  top: calc(100% / 3);
  left: 30%;
  width: 40%;
  height: calc(100% / 3);
}

.hive-cell .cell-fill {
  cursor: pointer;
  transition: all 100ms;
}

.hive-cell .cell-letter {
  pointer-events: none;
  text-transform: uppercase;
  text-anchor: right;
}

.hive-cell.primary {
  transform: translate(0,0);
}

.hive-cell.secondary.pos1 {
  transform: translate(-75%, -50%);
}

.hive-cell.secondary.pos2 {
  transform: translate(0, -100%);
}

.hive-cell.secondary.pos3 {
  transform: translate(75%, -50%);
}

.hive-cell.secondary.pos4 {
  transform: translate(75%, 50%);
}

.hive-cell.secondary.pos5 {
  transform: translate(0%, 100%);
}

.hive-cell.secondary.pos6 {
  transform: translate(-75%, 50%);
}
